.App {
  text-align: center;
}
#root {
  height: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.blue-smith {
  color: #003366 !important;
}
body {
  background-color: #003366;
  color: white;
  background-image: linear-gradient(rgba(47, 53, 63, 0.37), rgba(47, 53, 63, 0.37)), url("images/bg-service.jpg");
  background-size: auto, cover;
  background-position: top center;
}
a {
  color: #fff;
  text-decoration: none;
}
.hidden {
  display: none !important;
}
.ui.grid.no-padding {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0 !important;
}
.ui.grid.no-padding > * {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.ui.grid.no-padding.ui.grid > .column:not(.row) {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
@media only screen and (max-width: 767px) {
  .ui.grid.no-padding .ui.stackable.grid {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
.ui.grid .top-5 {
  margin-top: 5px !important;
}
.top-10 {
  margin-top: 10px !important;
}
.top-15 {
  margin-top: 15px !important;
}
.top-20 {
  margin-top: 20px !important;
}
.top-25 {
  margin-top: 25px !important;
}
.top-30 {
  margin-top: 30px !important;
}
.top-40 {
  margin-top: 40px !important;
}
.top-50 {
  margin-top: 50px !important;
}
.top-100 {
  margin-top: 100px !important;
}
.bottom-5 {
  margin-bottom: 5px !important;
}
.bottom-10 {
  margin-bottom: 10px !important;
}
.bottom-15 {
  margin-bottom: 15px !important;
}
.bottom-20 {
  margin-bottom: 20px !important;
}
.bottom-25 {
  margin-bottom: 25px !important;
}
.bottom-30 {
  margin-bottom: 30px !important;
}
.bottom-40 {
  margin-bottom: 40px !important;
}
.bottom-50 {
  margin-bottom: 50px !important;
}
.left-0 {
  margin-left: 0 !important;
}
.right-0 {
  margin-right: 0 !important;
}
.ui.link.menu .item:active,
.ui.menu .link.item:active,
.ui.menu a.item:active,
.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover,
.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  background: transparent;
  color: inherit;
}
.ui.menu.top-menu {
  background-color: #003366;
  height: 70px;
}
.ui.menu.top-menu.service-5f9548c4e4873c6f1cb68f05 {
  background-color: #e56db1;
}
.ui.menu.top-menu.service-5f9548b2e4873c6f1cb68f04 {
  background-color: #79059b;
}
.ui.menu.top-menu.service-602549c62e64078cbfdc373e {
  background-color: #44c0c5;
}
.ui.menu.top-menu.service-6164068842d24c2f851eb400 {
  background-color: #008037;
}
.ui.menu.top-menu.service-65a572243dd3aa9e8b1692b0 {
  background-color: #4281c8;
}
.ui.menu.top-menu.service-65a572063dd3aa9e8b1692af {
  background-color: #000000;
}
.ui.menu.top-menu .app-logo {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ui.menu.top-menu .item {
  border: none !important;
  padding: 0;
}
.ui.menu.top-menu .item:before {
  background: transparent;
}
.ui.menu.top-menu .home-link {
  position: absolute;
  right: 100px;
  padding: 7px 5px 5px 4px;
  color: #fff;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.ui.menu.top-menu .messages-link {
  position: absolute;
  right: 60px;
  padding: 8px 5px 5px 4px;
  color: #fff;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.ui.menu.top-menu .phone-link {
  position: absolute;
  right: 10px;
  border: 2px solid white;
  padding: 7px 5px 5px 5px;
  border-radius: 500px;
  color: #fff;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.phone-list .header {
  font-size: 1.5em;
  margin-bottom: 10px !important;
}
.phone-list .list .item {
  padding-top: 20px !important;
  margin-bottom: 10px !important;
}
.sidebar {
  padding-top: 70px;
}
.sidebar .ui.inverted.menu {
  background: #262829;
}
.sidebar .username {
  color: white !important;
  margin-top: 0;
  padding-top: 0.5em;
}
.sidebar .close {
  padding: 5px;
  z-index: 103;
  position: absolute;
  top: 5px;
  right: 5px;
}
.ui.menu.bottom-menu {
  background-color: #003366;
  height: 30px;
}
.ui.menu.bottom-menu.service-5f9548c4e4873c6f1cb68f05 {
  background-color: #e56db1;
}
.ui.menu.bottom-menu.service-5f9548b2e4873c6f1cb68f04 {
  background-color: #79059b;
}
.ui.menu.bottom-menu.service-602549c62e64078cbfdc373e {
  background-color: #44c0c5;
}
.ui.menu.bottom-menu.service-6164068842d24c2f851eb400 {
  background-color: #008037;
}
.ui.menu.bottom-menu.service-65a572243dd3aa9e8b1692b0 {
  background-color: #4281c8;
}
.ui.menu.bottom-menu.service-65a572063dd3aa9e8b1692af {
  background-color: #000000;
}
.ui.menu.bottom-menu .item {
  color: #fff;
}
.ui.menu.bottom-menu .item .icon {
  margin: 0 0 2px;
}
.ui.menu.bottom-menu .item.active span {
  border-bottom: 1px solid #d2d7da;
}
.ui.menu.bottom-menu .item .notification {
  top: -0.7em !important;
  margin-left: -10px !important;
  min-width: 1.5em;
  min-height: 1.5em;
  padding: 0.3em !important;
}
.main-content,
.container.main-content {
  padding-top: 70px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1224px) {
  .main-content,
  .container.main-content {
    width: auto !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}
.main-content.admin,
.container.main-content.admin {
  margin-left: 180px;
}
.app-button {
  color: white !important;
}
.app-button {
  background-image: linear-gradient(to bottom, #2d469e 0%, #1e2c5d 100%) !important;
}
.app-button.inactive {
  background-image: linear-gradient(to bottom, #2d469e 0%, #1e2c5d 100%) !important;
}
/*pelvic*/
.service-5f9548c4e4873c6f1cb68f05 .app-button {
  background-image: linear-gradient(to bottom, #e59cc5 0%, #e56db1 100%) !important;
}
.service-5f9548b2e4873c6f1cb68f04 .app-button {
  background-image: linear-gradient(to bottom, #bd5fd9 0%, #79059b 100%) !important;
}
.service-602549c62e64078cbfdc373e .app-button {
  background-image: linear-gradient(to bottom, #7deef3 0%, #44c0c5 100%) !important;
}
.service-6164068842d24c2f851eb400 .app-button {
  background-image: linear-gradient(to bottom, #0ccb5e 0%, #008037 100%) !important;
}
.service-65a572243dd3aa9e8b1692b0 .app-button {
  background-image: linear-gradient(to bottom, #70aaed 0%, #4281c8 100%) !important;
}
.service-65a572063dd3aa9e8b1692af .app-button {
  background-image: linear-gradient(to bottom, #3b3b3b 0%, #000000 100%) !important;
}
.page h2.ui.header,
.ui.modal.basic > .header:not(.ui) h2 {
  font-size: 1.9rem !important;
  letter-spacing: 0.2rem;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
  text-align: center;
  position: relative;
}
.ui.segment {
  color: #454545;
}
.ui.segment.cover,
.ui.segment .header-container {
  color: white;
}
.ui.modal {
  color: #454545;
}
.ui.modal.carousel .close {
  top: -1rem !important;
}
@media only screen and (max-width: 767px) {
  .ui.modal > .actions {
    padding: 1rem 1rem !important;
  }
}
.ui.modal > .actions .icon {
  margin-left: 10px;
}
.login a {
  color: #003366;
}
.login form {
  text-align: left;
}
.page .dimmer {
  min-height: 65px;
}
@media only screen and (min-width: 767px) {
  .home.page {
    padding-top: 5em;
  }
}
@media only screen and (max-width: 767px) {
  .home .column,
  .home .ui.stackable.grid > .column:not(.row) {
    padding: 0 !important;
  }
}
.home .ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.home .bloc {
  position: relative;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  max-height: 230px;
  font-size: 1.5rem;
  background: transparent;
}
.home .bloc.inactive div {
  opacity: 0.7;
}
.home .bloc.inactive::before {
  filter: grayscale(1);
}
.home .bloc div {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.home .bloc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: -2;
}
.home .bloc::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
  z-index: -1;
}
.home .bloc.running {
  border-left: 5px solid #003366;
}
.home .bloc.running::before {
  background-image: url("images/home-running.jpg");
  background-position: top -40px center;
}
.home .bloc.pt {
  border-left: 5px solid #003366;
}
.home .bloc.pt::before {
  background-image: url("images/home-pt.jpg");
  background-position: top 0px center;
}
.home .bloc.women {
  border-left: 5px solid #e56db1;
}
.home .bloc.women::before {
  background-image: url("images/home-women.jpg");
  background-position: top -20px center;
}
.home .bloc.head {
  border-left: 5px solid #79059b;
}
.home .bloc.head::before {
  background-position: top -20px center;
  background-image: url("images/home-head.jpg");
}
.home .bloc.arts {
  border-left: 5px solid #44c0c5;
}
.home .bloc.arts::before {
  background-position: top -20px center;
  background-image: url("images/home-arts.jpg");
}
.home .bloc.golf {
  border-left: 5px solid #008037;
}
.home .bloc.golf::before {
  background-position: top -20px center;
  background-image: url("images/home-golf.jpg");
}
.home .bloc.performance {
  border-left: 5px solid #000000;
}
.home .bloc.performance::before {
  background-position: top -20px center;
  background-image: url("images/home-performance.jpg");
}
.home .bloc.aquatic {
  border-left: 5px solid #4281c8;
}
.home .bloc.aquatic::before {
  background-position: top -20px center;
  background-image: url("images/home-aquatic.jpg");
}
.cover {
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  min-height: 330px;
  font-size: 1.5rem;
  background: transparent !important;
}
.cover div {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.cover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}
.cover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
  z-index: -1;
}
.cover.running::before {
  background-image: url("images/home-running.jpg");
}
.cover.pt::before {
  background-image: url("images/home-pt.jpg");
}
.cover.women::before {
  background-image: url("images/home-women.jpg");
}
.cover.head::before {
  background-image: url("images/home-head.jpg");
}
.cover.arts::before {
  background-image: url("images/home-arts.jpg");
}
.cover.golf::before {
  background-image: url("images/home-golf.jpg");
}
.cover.performance::before {
  background-image: url("images/home-performance.jpg");
}
.cover.aquatic::before {
  background-image: url("images/home-aquatic.jpg");
}
.service {
  padding-top: 20px;
}
.service .therapists {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.service .therapists .therapist {
  display: inline-block;
}
.service .therapists img {
  border: 3px solid #003366;
  border: 3px solid white;
  min-height: 150px;
}
.service .bloc {
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  height: 150px;
  max-width: 250px;
  border-radius: 1em;
  margin: 0 auto;
}
.service .bloc .bloc-content {
  position: absolute;
  top: 50%;
  /* poussé de la moitié de hauteur du référent */
  transform: translateY(-65%);
  /* tiré de la moitié de sa propre hauteur */
  left: 0;
  right: 0;
}
.service .bloc .bloc-content .icon {
  margin-bottom: 5px;
}
.user-protocols,
.user-profile,
.user-protocol,
.progress-cards,
.user-services,
.admin-services,
.user-videos {
  padding-bottom: 10px;
}
.user-protocols > .container,
.user-profile > .container,
.user-protocol > .container,
.progress-cards > .container,
.user-services > .container,
.admin-services > .container,
.user-videos > .container {
  max-width: 550px !important;
}
.user-protocols .list,
.user-profile .list,
.user-protocol .list,
.progress-cards .list,
.user-services .list,
.admin-services .list,
.user-videos .list {
  background-color: #fff;
  border-radius: 0.5em;
}
.user-protocols .ui.label,
.user-profile .ui.label,
.user-protocol .ui.label,
.progress-cards .ui.label,
.user-services .ui.label,
.admin-services .ui.label,
.user-videos .ui.label {
  width: 3em;
  height: 3em;
  margin: 0.5em !important;
  background-color: #003366 !important;
  border-color: #003366 !important;
  color: #fff;
  float: left;
}
.user-protocols .ui.label.inverted,
.user-profile .ui.label.inverted,
.user-protocol .ui.label.inverted,
.progress-cards .ui.label.inverted,
.user-services .ui.label.inverted,
.admin-services .ui.label.inverted,
.user-videos .ui.label.inverted {
  background-color: #fff !important;
  border-color: #003366 !important;
  color: #003366 !important;
  border-width: 2px;
}
.user-protocols .content,
.user-profile .content,
.user-protocol .content,
.progress-cards .content,
.user-services .content,
.admin-services .content,
.user-videos .content {
  margin-top: 0.5em !important;
  color: #454545;
}
.user-protocols .content .description,
.user-profile .content .description,
.user-protocol .content .description,
.progress-cards .content .description,
.user-services .content .description,
.admin-services .content .description,
.user-videos .content .description {
  font-size: 1rem;
}
.user-protocols .content .video,
.user-profile .content .video,
.user-protocol .content .video,
.progress-cards .content .video,
.user-services .content .video,
.admin-services .content .video,
.user-videos .content .video {
  width: 90%;
  display: block;
  padding: 1em;
  margin: 0 auto;
}
.user-protocols .content video,
.user-profile .content video,
.user-protocol .content video,
.progress-cards .content video,
.user-services .content video,
.admin-services .content video,
.user-videos .content video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}
/*
.service-6164068842d24c2f851eb400 {
  .user-protocols,
  .user-profile,
  .user-protocol,
  .progress-cards,
  .user-services,
  .admin-services,
  .user-videos {
    .ui.label {
      color: @golf-color;
      &.inverted {
        background-color: #fff !important;
        border-color: @golf-color!important;
        color: @golf-color!important;
        border-width: 2px;
      }
    }
  }
}
*/
.admin-services > .container {
  max-width: none !important;
}
.upload-form .icon.upload.cloud {
  color: #003366;
}
.service-5f9548c4e4873c6f1cb68f05 .upload-form .icon.upload.cloud {
  color: #e56db1;
}
.service-5f9548b2e4873c6f1cb68f04 .upload-form .icon.upload.cloud {
  color: #79059b;
}
.service-602549c62e64078cbfdc373e .upload-form .icon.upload.cloud {
  color: #44c0c5;
}
.service-6164068842d24c2f851eb400 .upload-form .icon.upload.cloud {
  color: #008037;
}
.service-65a572243dd3aa9e8b1692b0 .upload-form .icon.upload.cloud {
  color: #4281c8;
}
.service-65a572063dd3aa9e8b1692af .upload-form .icon.upload.cloud {
  color: #000000;
}
.user-profile .form.read input {
  border: none !important;
}
.user-protocol .ui.label {
  width: 3em;
  height: 3em;
  padding: 0.8em 0.5em 0.5em 0.5em !important;
}
.user-protocol .card.exercise {
  box-shadow: 0 1px 3px 0 #1f2d60, 0 0 0 1px #565963;
  margin: 0em;
  background-color: #003366;
}
.user-protocol .card.exercise .content,
.user-protocol .card.exercise .content .header,
.user-protocol .card.exercise .content .description {
  color: #fff;
}
.user-protocol .card.exercise .ui.embed {
  /* margin: 0.01em 1.9em;*/
  background: transparent;
}
.service-5f9548c4e4873c6f1cb68f05 .user-protocol .card.exercise {
  background-color: #e56db1;
}
.service-5f9548b2e4873c6f1cb68f04 .user-protocol .card.exercise {
  background-color: #79059b;
}
.service-602549c62e64078cbfdc373e .user-protocol .card.exercise {
  background-color: #44c0c5;
}
.service-6164068842d24c2f851eb400 .user-protocol .card.exercise {
  background-color: #008037;
}
.service-65a572243dd3aa9e8b1692b0 .user-protocol .card.exercise {
  background-color: #4281c8;
}
.service-65a572063dd3aa9e8b1692af .user-protocol .card.exercise {
  background-color: #000000;
}
.progress-cards .content,
.milestones-card .content {
  position: relative;
}
.progress-cards .content .app-button.add-media,
.milestones-card .content .app-button.add-media {
  margin-left: 10px;
}
.white-card {
  color: #454545;
}
.white-card a {
  color: #454545;
}
.description,
.content {
  white-space: pre-line;
}
.icon.grey {
  color: grey;
}
.message-feed {
  overflow: auto;
}
.user-messages > .container {
  max-width: 550px !important;
}
.user-messages .feed {
  background-color: #fff;
  border-radius: 0.5em;
  padding: 0.5em;
}
.user-messages .feed .event.self {
  flex-direction: row-reverse;
  background-color: #ededed;
}
.user-messages .feed .event.self div {
  text-align: right;
}
.user-messages .feed .event.self .content .extra.text {
  max-width: none;
  font-size: 0.9em;
}
.user-messages .feed .event.self .label {
  margin: 2px 6px;
}
.user-messages .feed .event.self > .label + .content {
  margin: 0.5em 1.14285714em 0.35714286em 0.5em;
}
.user-messages .feed .event.self .label img {
  margin: 2px;
}
.user-messages .date .author {
  font-weight: bold;
  font-size: 1em;
}
.user-messages .ui.label {
  width: 3em;
  height: 3em;
  margin: 0.5em !important;
  background-color: #003366 !important;
  border-color: #003366 !important;
  color: #fff;
  float: left;
}
.user-messages .content {
  margin-top: 0.7em !important;
  color: #454545;
  font-size: 1.1em;
}
.ui.left.sidebar.admin-menu {
  padding-top: 70px;
  width: 180px;
}
.ui.left.sidebar.admin-menu.inverted {
  background-color: #003366 !important;
}
.ui.left.sidebar.admin-menu .item {
  padding-left: 0.1em !important;
}
.ui.left.sidebar.admin-menu .item > .icon {
  float: left !important;
  margin-right: 12px !important;
}
.ui.left.sidebar.admin-menu vertical.menu .dropdown.item > .icon {
  margin-left: 0em;
}
.ui.left.sidebar.admin-menu .notification {
  top: -0.5em !important;
  margin-left: 0px !important;
  min-width: 1.5em;
  min-height: 1.5em;
  padding: 0.3em !important;
}
.admin .ui.menu.alpha-nav .item {
  min-width: 1em;
  padding: 0.6em;
  color: #003366;
}
.admin .ui.menu.alpha-nav .item:hover {
  color: #003366;
}
.admin .ui.menu.alpha-nav .item .input {
  margin-right: 2px;
}
.admin .ui.menu.alpha-nav .item .input > input {
  border: transparent;
}
.admin .ui.card > .content:first-child,
.admin .ui.cards > .card > .content:first-child {
  flex-grow: 0;
}
.admin .header-container {
  flex-grow: 0;
}
.admin .ui.menu a.item:hover,
.admin .ui.menu .active.item:hover {
  color: #003366;
}
.admin-patients .card a,
.admin-patient .card a {
  color: #003366;
}
.admin-patients .card .content > .description,
.admin-patient .card .content > .description {
  clear: none;
}
.admin-patients .content-cards .card .header-container,
.admin-patient .content-cards .card .header-container {
  background-color: #003366;
}
.admin-patients .content-cards .card .header-container .header,
.admin-patient .content-cards .card .header-container .header {
  color: white;
}
.admin-patients .content-cards .card .card-container,
.admin-patient .content-cards .card .card-container {
  overflow: auto;
  max-height: 25em;
}
.admin-patients .new-form-card,
.admin-patient .new-form-card {
  margin: 0.875em 0.5em !important;
}
.admin-patients .video,
.admin-patient .video {
  width: 90%;
  display: block;
  padding: 1em;
  margin: 0 auto;
}
.admin-patients video,
.admin-patient video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}
.admin-patients .main-content-row > .column,
.admin-patient .main-content-row > .column {
  margin-bottom: 10px !important;
}
.rep-scheme.description {
  color: #949494 !important;
}
.progress-photos img {
  height: 5rem;
}
